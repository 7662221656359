import { Box, Text, Flex, Button, Image } from "@chakra-ui/react";
import { useAuthStore, useOnboardingStore, useQuestsStore } from "@olagg/store";
import { useEffect } from "react";
import Modal from "../../../components/Modal/Modal";
import { useTranslation } from "react-i18next";
import { QUEST_STATUS } from "@olagg/db-types";
import useQuest from '../hooks/useQuest';
import QuestWeb3Button from "../components/QuestModal/web3/QuestWeb3Button";
import { cdn } from "../../../utils/cloudflareCdn";

const Web3Modal = ({ reloadQuests }) => {
    const { i18n, t } = useTranslation();
    const { activeQuest: quest, setActiveQuest } = useQuestsStore();
    const { openOnboarding } = useOnboardingStore()
    const { loggedIn } = useAuthStore()
    const isOpen = quest?.trigger === 'web3.quest.completed' && quest?.status === QUEST_STATUS.PENDING;
    const onClose = () => setActiveQuest(null);

    const disabled = quest?.status === QUEST_STATUS.DISABLED

    const title = () => {
        const _title = i18n.language === 'es' ? quest.title : quest.extraData?.[i18n.language]?.title || quest.title
        if (quest.recursive) {
            const n = quest.iteration * quest.multiplier
            return _title?.replace('[N]', n.toString())
        }
        return _title
    }

    return (
        <Modal onClose={onClose} isOpen={isOpen} >
            <Box p='5'>
                <Box py='2' borderBottom='1px solid #BEB079'>
                    <Text textAlign='center' fontSize='1.5rem' fontWeight='600'>{title()}</Text>
                </Box>

                {quest.banner && (
                    <Flex position={'relative'} justifyContent='center'>
                        <Image
                            objectFit='cover'
                            w='100%'
                            maxW='320px'
                            borderRadius={'20px'}
                            height="100%"
                            backgroundPosition="center center"
                            src={cdn(quest.banner, { w: '320px' })}
                            zIndex={1}
                            my='20px'
                        />
                    </Flex>
                )}

                <Text py='5' textAlign='center' fontSize='1.25rem'>{
                    quest?.description ?
                        i18n.language === 'es' ? quest?.description : (i18n.language === 'en' ? (quest.extraData.en?.description && quest.extraData.en?.description) || quest?.description : (quest.extraData.pt?.description && quest.extraData.pt?.description) || quest?.description) :
                        i18n.language === 'es' ? quest?.title : (i18n.language === 'en' ? (quest.extraData.en?.title && quest.extraData.en?.title) || quest?.title : (quest.extraData.pt?.title && quest.extraData.pt?.title) || quest?.title)
                }</Text>
                <Flex w='full' justifyContent='center' alignItems='center' flexDirection={'column'}>
                    <QuestWeb3Button
                        quest={quest}
                        reloadQuests={reloadQuests}
                        loggedIn={loggedIn}
                        disabled={disabled}
                        openOnboarding={openOnboarding}
                    />
                </Flex>

            </Box>
        </Modal>
    )
};

export default Web3Modal;
