import {
  Image,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  ModalFooter,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { cdn } from '../../../../../utils/cloudflareCdn'

const ModalWeb3Error = ({ isOpen, onClose, quest, missingAmount, reloadQuests }) => {

  const { t, i18n } = useTranslation();
  const lang = i18n.language

  const closeModal = () => {
    reloadQuests()
    onClose()
  }

  return (
    <Modal isCentered size='lg' isOpen={isOpen} onClose={closeModal} blockScrollOnMount={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p={'24px 24px 0 24px'} overflowY={{ base: 'auto', md: 'hidden' }} maxH={{ base: '70vh', md: 'auto' }}>
          <Flex alignItems={'center'} justifyContent='center' w='full' flexDirection='column'>

            <Flex position={'relative'} justifyContent='center'>
              <Image
                objectFit='cover'
                w='100%'
                maxW='320px'
                borderRadius={'20px'}
                height="100%"
                backgroundPosition="center center"
                src={cdn(quest.banner, { w: '320px' })}
                zIndex={1}
                my='20px'
              />
            </Flex>

            <Text color="black" fontSize={'22px'} fontWeight='700' lineHeight={'120%'} textAlign={'center'}>
              {t('Quests.web3.modalError.title')} {quest?.tokenName ?? 'tokens'}<br /> {t('Quests.web3.modalError.title2')}
            </Text>
            
            <Text color="gray.500" fontSize={'18px'} fontWeight='400' lineHeight={'120%'} textAlign={'center'} mt='8' mb='3'>
              {t('Quests.web3.modalError.lead', { missingAmount: missingAmount ? Number(missingAmount).toFixed(2) : '', tokenName: quest?.tokenName?.toUpperCase() ?? 'tokens' })}
            </Text>

          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex w={'100%'} justifyContent={'center'} sx={{
            ' a': {
              width: '100%'
            }
          }}>
            <a href={quest?.howToGetTokenUrl} target='_blank'>
              <Button as='span' variant={'questsDefault'} fontSize={'14px'} fontWeight='500' w={'100%'}>
                {t('Quests.web3.modalError.cta')}
              </Button>
            </a>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )

}

export default ModalWeb3Error