import {
  Image,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  ModalFooter,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { HiOutlineInformationCircle } from "react-icons/hi2";
import { useState } from 'react';

const ModalWeb3UnsetWallet = ({ isOpen, onClose, icon, address, handleUnsetWallet, reloadQuests }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const unsetWallet = async () => {
    setIsLoading(true);
    await handleUnsetWallet();
    reloadQuests();
    setIsLoading(false);
    onClose();
  }

  return (
    <Modal isCentered size='lg' isOpen={isOpen} onClose={onClose} blockScrollOnMount={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />

        <ModalBody p={'24px 24px 0 24px'} overflowY={{ base: 'auto', md: 'hidden' }} maxH={{ base: '70vh', md: 'auto' }}>
          <Flex alignItems={'center'} justifyContent='center' w='full' flexDirection='column'>
            <Text color="black" fontSize={'18px'} fontWeight='700' mt='10' mb='2' textAlign={'center'}>{t('Quests.web3.modalUnsetWallet.title')}</Text>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Flex w={'100%'} justifyContent={'center'} flexDirection='column'>
            <Flex
                bgColor='#F2EDFF'
                p='4px 8px'
                borderRadius={'4px'}
                gap='8px'
                mt='12px'
                mb='8px'
                w='full'
                alignItems='center'
                justifyContent='center'
            >
              <Flex border={'1px solid #4E10FF'} w='24px' h='24px' borderRadius='100px' alignItems={'center'} justifyContent={'center'}>
                <Image src={icon} width='16px' height='16px' />
              </Flex>
              <Text color='#0C0324' fontSize='14px'>{address}</Text>
            </Flex>
            <Flex w='full' justifyContent='space-between' gap='3' mt='12px'>
              <Button variant='outlinedGradient' color='black' onClick={unsetWallet} w='full' isLoading={isLoading}>{t('Quests.web3.modalUnsetWallet.yes')}</Button>
              <Button variant='filledGradient' onClick={onClose} w='full'>{t('Quests.web3.modalUnsetWallet.no')}</Button>
            </Flex>
            <Flex w='full' justifyContent='start' alignItems='center' mt='5'>
              <HiOutlineInformationCircle color='#AB17DF' size='25px'/>
              <Text color='black' fontSize='12px' ml='1'>{t('Quests.web3.modalUnsetWallet.tip')}</Text>
            </Flex>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )

}

export default ModalWeb3UnsetWallet