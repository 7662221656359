import { Box, Text, Flex } from "@chakra-ui/react";
import { useQuestsStore } from "@olagg/store";
import { useEffect } from "react";
import Modal from "../../../components/Modal/Modal";
import { useTranslation } from "react-i18next";
import ConnectWallet from '@olagg/ui-kit/ConnectWallet'
import { QUEST_STATUS } from "@olagg/db-types";
import { useActiveAccount } from 'thirdweb/react';
import useQuest from '../hooks/useQuest';

const LinkWalletModal = () => {
    const { i18n, t } = useTranslation();
    const { activeQuest: quest, setActiveQuest } = useQuestsStore();
    const isOpen = quest?.trigger === 'quest.wallet.linked' && quest?.status === QUEST_STATUS.PENDING;
    const onClose = () => setActiveQuest(null);
    const activeAccount = useActiveAccount();
    const { getUserQuest } = useQuest();

    useEffect(() => {
        if (quest?.trigger === 'quest.wallet.linked' && activeAccount?.address && quest?.status === QUEST_STATUS.PENDING) {
            setTimeout(() => {
                getUserQuest(quest?.id)
                    .then((updatedUserQuest) => {
                        setActiveQuest({ ...quest, status: updatedUserQuest?.status?.toUpperCase() });
                    })
            }, 1000);
        }
    }, [activeAccount]);

    return (
        <Modal onClose={onClose} isOpen={isOpen} >
            <Box p='5'>
                <Box py='2' borderBottom='1px solid #BEB079'>
                    <Text textAlign='center' fontSize='1.5rem' fontWeight='600'>{t('ConnectWallet.questModal.title')}</Text>
                </Box>
                <Text py='5' textAlign='center' fontSize='1.25rem'>{
                    quest?.description ?
                        i18n.language === 'es' ? quest?.description : (i18n.language === 'en' ? (quest.extraData.en?.description && quest.extraData.en?.description) || quest?.description : (quest.extraData.pt?.description && quest.extraData.pt?.description) || quest?.description) :
                        i18n.language === 'es' ? quest?.title : (i18n.language === 'en' ? (quest.extraData.en?.title && quest.extraData.en?.title) || quest?.title : (quest.extraData.pt?.title && quest.extraData.pt?.title) || quest?.title)
                }</Text>
                <Flex w='full' justifyContent='center' alignItems='center'>
                    <Box><ConnectWallet /></Box>
                </Flex>
                <Text py='1' textAlign='center' fontSize='1rem'>
                    {activeAccount && t('ConnectWallet.questModal.validating')}
                </Text>
            </Box>
        </Modal>
    )
};

export default LinkWalletModal;
