import {
  Image,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  ModalFooter,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Gift from '../assets/gift.png'
import XpGradient from '../assets/xp.svg'
import { useState } from 'react';
import useQuest from '../../../hooks/useQuest'
import { useAuthStore, useQuestsStore } from '@olagg/store'

const ModalWeb3Success = ({ isOpen, onClose, quest, reloadQuests }) => {

  const { t, i18n } = useTranslation();
  const lang = i18n.language
  const { getMe } = useAuthStore()

  const { setActiveQuest } = useQuestsStore();

  const closeModal = async () => {
    await reloadQuests()
    await getMe()
    setActiveQuest(null);
    onClose()
  }

  return (
    <Modal isCentered size='lg' isOpen={isOpen} onClose={closeModal} blockScrollOnMount={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p={'24px 24px 0 24px'} overflowY={{ base: 'auto', md: 'hidden' }} maxH={{ base: '70vh', md: 'auto' }}>
          <Flex alignItems={'center'} justifyContent='center' w='full' flexDirection='column' pb='45px'>
            <Flex position={'relative'} justifyContent='center'>
              <Flex>
                <Image src={Gift} maxW='350px' my={'20px'} />
              </Flex>
            </Flex>

            <Flex>
              <Text color="black" fontSize={'18px'} fontWeight='700' lineHeight={'120%'} textAlign={'center'}>¡{t('Quests.web3.modalSuccess.title')}</Text>
              <Flex alignItems={'center '} gap='2px' ml='1' p={'2px 10px'} background='linear-gradient(90deg, rgba(78, 16, 255, 0.10) 0%, rgba(171, 23, 223, 0.10) 100%)' borderRadius={'2px'}>
                <Text variant={'filledGradient'} fontSize={'14px'} fontWeight='700'>{quest?.score}</Text>
                <Image src={XpGradient} />
              </Flex>
              <Text color="black" fontSize={'18px'} fontWeight='700' lineHeight={'120%'} ml='4px' textAlign={'center'}>!</Text>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button onClick={closeModal} as='span' cursor='pointer' variant={'filledGradient'} fontSize={'14px'} fontWeight='500' w={'100%'}>
            {t('Quests.web3.modalSuccess.closeButton')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )

}

export default ModalWeb3Success