import { BackendError, Method, QuestEndpoints, fetcher } from "@olagg/api-hooks"
import { Quest, QuestPostBody } from "@olagg/db-types"
import { analytics } from '../../../analytics'
import { useAuthStore } from '@olagg/store'
import { useToast } from "@chakra-ui/react"
import { GetUserQuest } from "@olagg/api-hooks/endpoints/endpoints"
import { useLocation } from 'react-router-dom'
import { AdTrackingContext } from '../../../contexts/adTrackingContext'
import { useContext } from "react"
import { useTranslation } from "react-i18next"

export type IAllQuests = {
    page: number
    limit: number
    ownerId?: string
}

export type OwnerWithQuests = {
    id: string,
    name: string,
    order: number,
    quests: Quest[]
}

const useQuest = () => {
    const { fcb, fbp } = useContext(AdTrackingContext)
    const { pathname } = useLocation()
    const pathnameExtract = pathname.split(`/`)[2]

    const { t } = useTranslation();
    
    const toast = useToast()
    const { getMe } = useAuthStore()

    const all = async (filters: IAllQuests) =>
        fetcher<{ quests: Quest[], meta: { count: number } }>(QuestEndpoints.all(filters))

    const byOwnerId = async (ownerId) => {
        let endpoint = { method: Method.GET, path: `/quests?owner_id=${ownerId}` }
        return fetcher(endpoint)
    }

    const getQuestByTrigger = async (trigger: string) =>
        fetcher<{ quest: Quest }>(QuestEndpoints.byTrigger(trigger))

    const getUserQuest = async (questId: string) => {
        return (fetcher(GetUserQuest(questId)));
    }

    const groupedByOwner = (quests: Quest[]) => {
        const owners: OwnerWithQuests[] = []
        quests.forEach(quest => {
            const existingOwner = owners.find(owner => owner.id === quest.owner.id)
            if (!existingOwner) {
                owners.push({ ...quest.owner, quests: [quest] })
            } else {
                existingOwner.quests.push(quest)
            }
        })
        return owners.sort((a, b) => a.order - b.order)
    }

    const completeQuest = async (quest: Quest, body?: QuestPostBody) => {
        let endpoint = { method: Method.PUT, path: quest.action, body: {} }
        if (body) endpoint.body = body
        return fetcher(endpoint)
            .then(() => {
                let props = {
                    quest_id: quest.id,
                    quest_name: quest.title,
                    quest_xp: quest.score,
                    owner: pathnameExtract,
                    user_agent: navigator.userAgent,
                }
                if (fcb != null) props.fbc = fcb
                if (fbp != null) props.fbp = fbp
                analytics()
                    ?.track('Quest Completed', props)

                if (quest.callback) window.open(quest.callback, '_blank')

            })
            .catch((error: BackendError) => {
                if (typeof error.error === 'object') {
                    if (error.error["callToAction"]) {
                        window.open(error.error["callToAction"], '_blank', 'noopener,noreferrer')
                        return
                    }
                }
                if (body) return false
            })
    }

    const claimQuest = async (quest: Quest) => {
        if (quest.trigger === 'user.account.login') {
            let props = {
                quest_id: quest.id,
                quest_name: quest.title,
                quest_xp: quest.score,
                owner: pathnameExtract,
                user_agent: navigator.userAgent,
            }
            if (fcb != null) props.fbc = fcb
            if (fbp != null) props.fbp = fbp

            analytics()
                ?.track('Quest Started', props)

            analytics()
                ?.track('Quest Completed', props)
        }
        await fetcher(QuestEndpoints.claim(quest.id))
            .then(async () => {
                await getMe()
                let props = {
                    quest_id: quest.id,
                    quest_name: quest.title,
                    quest_xp: quest.score,
                    owner: pathnameExtract,
                    user_agent: navigator.userAgent,
                }
                if (fcb != null) props.fbc = fcb
                if (fbp != null) props.fbp = fbp
                analytics()
                    ?.track('Quest XP claimed', props)
            })
            .catch((error: BackendError) => {
                const cta = JSON.parse(error.message)
                if (cta?.callToAction) window.open(cta.callToAction, '_blank', 'noopener,noreferrer')
                toast({
                    title: "Error",
                    description: error.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
            })
    }

    const setVerifiedWallet = async (questId: string, wallet: string) => {
        return fetcher(QuestEndpoints.setQuestWallet(questId, wallet))
            .then((response) => {
                toast({
                    title: t('Quests.web3.walletVerified'),
                    colorScheme: "olaggPink",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                    position: "bottom-right"
                })
                return true
            })
            .catch((error: BackendError) => {
                const errorMessage = (() => {
                    switch (error?.error) {
                        case 'address_already_set':
                            return t('Quests.web3.walletAlreadySet');
                        case 'quest_not_pending':
                            return t('Quests.web3.questNotPending');
                        default:
                            return t('Quests.web3.cantVerifiedWallet');
                    }
                })();
                toast({
                    title: errorMessage,
                    colorScheme: "olaggPink",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                    position: "bottom-right"
                })
                console.log(error)
                return false
            })
    }

    const unsetVerifiedWallet = async (questId: string): Promise<boolean> => {
        return fetcher(QuestEndpoints.unsetQuestWallet(questId))
            .then((response) => {
                toast({
                    title: t('Quests.web3.walletUnset'),
                    colorScheme: "olaggPink",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                    position: "bottom-right"
                })
                return true
            })
            .catch((error: BackendError) => {
                const errorMessage = (() => {
                    switch (error?.error) {
                        case 'quest_not_pending':
                            return t('Quests.web3.questNotPendingUnset');
                        case 'quest_already_claimed_once':
                            return t('Quests.web3.questAlreadyClaimedOnce');
                        default:
                            return t('Quests.web3.cantUnsetWallet');
                    }
                })();
                toast({
                    title: errorMessage,
                    colorScheme: "olaggPink",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                    position: "bottom-right"
                })
                console.log(error)
                return false
            })
    }

    const completeWeb3Quest = async (questId: string) => {
        return fetcher(QuestEndpoints.completeWeb3Quest(questId))
            .then((response) => {
                return response
            })
            .catch((error: BackendError) => {
                console.log(error)
                return false
            })
    }

    const checkStatusWeb3Quest = async (questId: string) => {
        return fetcher(QuestEndpoints.checkStatusWeb3Quest(questId))
            .then((response) => {
                return response
            })
            .catch((error: BackendError) => {
                console.log(error)
                return false
            })
    }

    return {
        all,
        byOwnerId,
        groupedByOwner,
        completeQuest,
        claimQuest,
        getUserQuest,
        setVerifiedWallet,
        unsetVerifiedWallet,
        completeWeb3Quest,
        checkStatusWeb3Quest,
        getQuestByTrigger
    }
}

export default useQuest;
