import React, { useState, useEffect } from 'react';
import moment from 'moment/moment.js'
import 'moment/min/locales'

type CountdownProps = {
    timestamp: string
    seconds: number
    onExpired: () => void
}

const Countdown = ({ timestamp, seconds, onExpired }: CountdownProps) => {
    const [timeLeft, setTimeLeft] = useState({ hours: 0, minutes: 0, seconds: 0 });
    const [isExpired, setIsExpired] = useState(false);

    const convertToLocal = (utcTimestamp) => {
        return moment.utc(utcTimestamp).local().toLocaleString()
    };

    useEffect(() => {
        let targetTime;

        if (timestamp && seconds) {
            // Si ambos parámetros están definidos, calcular el tiempo objetivo
            let utcToLocal = convertToLocal(timestamp)
            targetTime = new Date(utcToLocal).getTime() + (seconds * 1000);
        } else {
            // Si no hay parámetros, calcular tiempo objetivo hasta mañana a la medianoche
            const now = new Date();
            const tomorrow = new Date();
            tomorrow.setDate(now.getDate() + 1);
            tomorrow.setHours(0, 0, 0, 0); // Medianoche de mañana
            targetTime = tomorrow.getTime();
        }

        const interval = setInterval(() => {
            const now = Date.now();
            const difference = targetTime - now;

            if (difference <= 0) {
                clearInterval(interval);
                setTimeLeft({ hours: 0, minutes: 0, seconds: 0 });
                setIsExpired(true);
            } else {
                const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((difference / (1000 * 60)) % 60);
                const seconds = Math.floor((difference / 1000) % 60);
                setTimeLeft({ hours, minutes, seconds });
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [timestamp, seconds]);

    useEffect(() => {
        if (isExpired) onExpired()
    }, [isExpired])

    return (
        <div>
            {!isExpired && (
                <p>
                    {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
                </p>
            )}
        </div>
    );
};

export default Countdown;